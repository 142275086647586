<template>
  <CButtonGroup role="group">
    <CButton
      v-for="item in data"
      color="primary"
      :variant="getCurrentValue === item.id ? null : 'outline'"
      @click="setValue(item.id)">{{ item.value }}</CButton>
  </CButtonGroup>
</template>

<script>
export default {
  name: "RadioSelect",
  emits: ['input'],
  props: {
    value: {
      type: Number,
      default: null
    },
    data: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  mounted() {
    this.$emit('input', this.currentValue)
  },
  methods: {
    setValue(value) {
      this.currentValue=value
      this.$emit('input', value)
    }
  },
  computed: {
    getCurrentValue() {
      return this.currentValue
    }
  }
}
</script>

<style scoped>

</style>
