<template>
  <CInputGroup size="sm" class="mb-3">
    <CFormInput placeholder="Поиск" aria-label="Поиск"/>
    <CButton type="button" color="secondary" variant="outline">
      <CIcon icon="cil-filter-x" />
    </CButton>
    <CButton  type="button" color="primary" variant="outline">
      <CIcon icon="cil-search" />
    </CButton>
  </CInputGroup>
</template>

<script>
export default {
  name: "SearchInput"
}
</script>

<style scoped>

</style>
