export default function getActionsUseCase()
{
  return [
    {
      label: "Добавить",
      icon: "cil-plus",
      requireItems: 0,
      name:"add",
      visible: true
    },
    {
      label: "Изменить",
      icon: "cil-pencil",
      requireItems: 1,
      name:"edit",
      visible: true
    },
    {
      label: "Сменить пароль",
      icon: "cil-lock-locked",
      requireItems: 1,
      name: "editPassword",
      visible: true
    },
    {
      label: "Удалить",
      icon: "cil-trash",
      requireItems: Number.MAX_SAFE_INTEGER,
      name:"delete",
      visible: true
    },
    {
      label: "Скрыть",
      icon: "cil-flip",
      requireItems: Number.MAX_SAFE_INTEGER,
      name:"hide",
      visible: true
    },
    {
      label: "Открыть в новой вкладке",
      icon: "cil-browser",
      requireItems: 1,
      name:"openInBrowser",
      visible: true
    },
    {
      label: "Отписать",
      icon: "cil-trash",
      requireItems: Number.MAX_SAFE_INTEGER,
      name:"unsubscribe",
      visible: true
    },
    {
      label: "Файлом",
      icon: "cil-cloud-upload",
      requireItems: 0,
      name:"upload",
      visible: true
    },
    {
      label: "Отправить",
      icon: "cil-send",
      requireItems: 0,
      name:"sendSMS",
      visible: true
    },
    {
      label: "Отправить",
      icon: "cil-send",
      requireItems: 0,
      name:"sendSMS",
      visible: true
    },
    {
      label: "Отправить",
      icon: "cil-send",
      requireItems: 0,
      name:"sendSMS",
      visible: true
    },
    {
      label: "Выгрузить b2c",
      icon: "cil-cloud-download",
      requireItems: 0,
      name:"exportB2C",
      controller: "export",
      method: "exportb2c",
      isLoading: false,
      visible: true
    },
    {
      label: "Одобрить",
      icon: "cil-thumb-up",
      controller: "requests",
      method: "approve",
      requireItems: Number.MAX_SAFE_INTEGER,
      name:"requestApprove",
      visible: true
    },
    {
      label: "Закрыть",
      icon: "cil-thumb-down",
      requireItems: 1,
      name:"requestClose",
      visible: false
    },
    {
      label: "Отклонить",
      icon: "cil-thumb-down",
      requireItems: 1,
      name:"requestDecline",
      visible: false
    },
    {
      label: "Выгрузить xlsx",
      icon: "cil-cloud-download",
      requireItems: 0,
      name:"exportExportbase",
      controller: "export",
      method: "exportbase",
      isLoading: false,
      visible: true
    },
    {
      label: "Выгрузить в Xlsx",
      icon: "cil-cloud-download",
      requireItems: 0,
      name:"exportTemplates",
      controller: "export",
      method: "templates",
      isLoading: false,
      visible: true
    },
  ]
}
