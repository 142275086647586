function getEvents(element, form)
{
  switch (element.type) {
    case "text":
    case "textarea":
      return {
        input: event => {
          form[element.name] = event.target.value
        }
      }
    case "select":
    case "radio":
    case "daterange":
      return {
        input: item => {
          form[element.name] = item
        }
      }
    case "file":
      return {
        input: event => {
          form[element.name] = event.target.files[0]
        }
      }
    case "button":
      return {
        click: e => this.search()
      }
    default:
      return {}
  }
}

function getProps(element) {
  switch (element.type) {
    case "hidden":
      return {
        placeholder: element.label,
        value: element.value,
        disabled: true
      }
    case "text":
    case "textarea":
      return {
        placeholder: element.label,
        value: element.value
      }
    case "select":
      return {
        value: element.value,
        data: element.data.map(item => { return {value: item.value, ID: item.id} })
      }
    case "radio":
      return {
        value: element.value,
        data: element.data
      }
    case "button":
      return {
        color: "primary",
        textContent: element.label
      }
    case "daterange":
      return {
        minDate: element.data.start,
        maxDate: element.data.end,
        masks: {input: element.format}
      }
    default:
      return {}
  }
}

function getComponent(type) {
  switch (type) {
    case "hidden":
    case "text":
      return "TextFilter"
    case "select":
      return "SelectFilter"
    case "button":
      return "CButton"
    case "daterange":
      return "DateRangePicker"
    case "radio":
      return "RadioSelect"
    case "textarea":
      return "CFormTextarea"
    case "file":
      return "FilePicker"
    default:
      return "div"
  }
}

export {
  getEvents,
  getProps,
  getComponent
}
