<template>
  <CCard class="mb-4">
    <SmcActionBar
      ref="actionBar"
      :response-data="responseData"
      :selected-items="selectedItems"
      :is-search-visible="false"
      @action="barAction"

    />
    <FilterWidget
      :filters="filters"
      @search="search"
    />
    <SmcBaseTable
      :response-data="responseData"
      @selectedRows="selectedRows"
      :add-checkboxes="true"
      :is-loading="isLoading"
      @tableAction="tableAction"
      @pageChange="pageChange"
      @orderChange="orderChange"
    />
  </CCard>
</template>

<script>
import SmcBaseTable from "../../support/smc/SmcBaseTable";
import {ref} from "vue";
import {sendRequest} from "../../../services/api";
import SmcActionBar from "../../support/smc/SmcActionBar";
import {useRoute} from "vue-router";
import FilterWidget from "../../widgets/FilterWidget";
import getFormFields from "../../../domain/use_case/getFormFields";

export default {
  name: "ClientsTable",
  components: {FilterWidget, SmcActionBar, SmcBaseTable},
  props: {
    controller: String,
    method: String,
  },
  data() {
    return {
    }
  },
  setup(props) {
    let responseData = ref({})
    let fieldsOrder = ref({})
    let pageInfo = ref({})
    let isLoading = ref(true)
    let filters = ref(null)
    let route = useRoute()
    let selectedItems  = ref([])
    let searchFields = ref( {})


    function reload() {
      selectedItems.value = []
      isLoading.value = true
      console.log("reload")

      sendRequest(
        props.controller,
        props.method,
        {...{
          ID: route.params.id,
          page: pageInfo.value.page,
          size: pageInfo.value.size,
          order: fieldsOrder.value
        }, ...searchFields.value }

        ).then(response => {
          console.log()
        responseData.value = response
        isLoading.value = false
        filters.value = response.data[0].result.filters
        response.data[0].result.filters.forEach(filter => {
          filter.value && (searchFields.value[filter.name] = filter.value)
        })
      })
    }

    reload()

    return {
      responseData,
      isLoading,
      pageInfo,
      selectedItems,
      reload,
      filters,
      fieldsOrder,
      searchFields
    }
  },
  methods: {
    search(fields) {
      this.searchFields = fields
      this.reload()
    },
    orderChange(order) {
      if (order.direction === "") {
        delete this.fieldsOrder[order.field]
      } else {
        this.fieldsOrder[order.field] = order.direction
      }
      this.reload()
    },
    pageChange(pageInfo) {
      this.pageInfo = pageInfo
      this.reload()
    },
    barAction(data) {
      console.log(data)
      switch (data) {
        case 'delete':
        case 'add':
        case 'edit':
        case 'upload':
        case 'sendSMS':
        case 'requestClose':
        case 'requestDecline':
        case 'requestApprove':
          this.reload()
        default:
          return
      }
    },
    tableAction(data, row) {
      if (data.type === 'inline-link') {
        const name = this.$route.path + "_" + data.controller + "_" + data.method
        if (!this.$router.hasRoute(name)) {
          this.$router.addRoute("Home", {
            name: name,
            path: this.$route.path + '/' + data.method + '/:id',
            props: {
              controller: data.controller,
              method: data.method
            },
            component: () => import('@/views/configurator/clients/ClientsTable'),
          })
        }
        this.$router.push(this.$route.path + '/' + data.method + '/' + data.data.ID)
      } else if (data.type === 'inline_form') {
        console.log(data)
        this.selectedItems = [row]
        if (data.controller === "requests" && data.method === "close") {
          this.$refs.actionBar.callActionByLabel("Закрыть")
        }
        if (data.controller === "requests" && data.method === "decline") {
          this.$refs.actionBar.callActionByLabel("Отклонить")
        }
        // this.selectedItems = []
      }
    },
    selectedRows(rows) {
      this.selectedItems = rows
    },
  }
}
</script>

<style scoped>
.nav-export {
  margin-left: auto;
}

.nav-export div {
  top: 0.3em;
  right: 0.5em;
}
</style>
