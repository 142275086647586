<template>
  <BaseModal size="lg" alignment="center" :is-visible="visible" @close="visible = false" backdrop="static">
    <CModalHeader>
      <CModalTitle>{{ this.formModel.formTitle }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm @submit.prevent>
        <CRow class="mb-3" v-for="field in getFields()">
          <CFormLabel  class="col-sm-2 col-form-label">{{ field.label }}</CFormLabel>
          <CCol sm="10">
            <component
              :is="getComponent(field.type)"
              v-bind="getProps(field)"
              v-on="getEvents(field)"
            />
          </CCol>
        </CRow>
        <!--        <InputList :items="[1,2,3]"/>-->
      </CForm>


    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { this.visible = false }">
        Отменить
      </CButton>
      <CLoadingButton
        :disabled-on-loading="true"
        :loading="loading"
        color="primary"
        @click="confirm">Сохранить
      </CLoadingButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal";
import {getComponent, getProps, getEvents} from "../../domain/use_case/componentUseCases";
import SelectFilter from "./filter/SelectFilter";
import DateRangePicker from "./form/DateRangePicker";
import TextFilter from "./filter/TextFilter";
import RadioSelect from "./form/RadioSelect";
import FilePicker from "./form/FilePicker";
import {CFormTextarea} from "@coreui/vue-pro";
import {sendRequest} from "../../services/api";


export default {
  name: "FormModalV2",
  components: {BaseModal, TextFilter, SelectFilter, DateRangePicker, RadioSelect, CFormTextarea, FilePicker},
  emits: ["close", "confirm"],
  props: {
    isVisible: {type: Boolean, default: false},
    formModel: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      visible: this.isVisible,
      loading: false,
      form: {}
    }
  },
  methods: {
    confirm() {
      console.log(this.form)
      this.loading = true
      sendRequest(this.formModel.controller, this.formModel.method, this.form)
        .then(r => {
          this.loading = false
          if (r.data[0].meta.success) {
            this.visible = false
            this.$emit('confirm', this.formModel.actionName)
          }
          if (r.data[0].meta.success) {
            this.visible = false
            this.$toast.success(r.data[0].result.message)
          } else {
            this.$toast.error(r.data[0].result.message)
          }
        })
    },
    getEvents(element) {
      return getEvents(element, this.form)
    },
    getProps(element) {
      return getProps(element)
    },
    getFields() {
      console.log("getFields", this.formModel.data)
      return this.formModel.data.filter(item => item.type !== 'hidden').sort((a,b) => {
        return a.index < b.index ? -1 : 1;
      })
    },
    getComponent(type) {
      return getComponent(type)
    }
  },
  watch: {
    visible: function(newVal) {
      if (!newVal) {
        this.$emit("close")
      }
    },
    isVisible: function(newVal) {
      this.visible = newVal
    },
    formModel: function(newVal) {
      this.form = {}
      newVal.data && newVal.data.forEach(item => {
        this.form[item.name] = item.value
      })
    }
  }
}
</script>

<style scoped>

</style>
