<template>
  <BaseModal alignment="center" :visible="visible">
    <CModalHeader>
      <CModalTitle>Внимание!</CModalTitle>
    </CModalHeader>
    <CModalBody class="question">
      <CIcon size="xl" icon="cil-info"/> Удалить выбранные элементы?
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="close">
        Отмена
      </CButton>
      <CLoadingButton
        :disabled-on-loading="true"
        :loading="loading"
        color="primary"
        @click="confirm">Удалить</CLoadingButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>
import {sendRequest} from "../../services/api";
import BaseModal from "./BaseModal";

export default {
  name: "DeleteModal",
  components: {BaseModal},
  emits: ['close','deleted'],
  props: {
    isWindowVisible: false,
    controller: "",
    method: "",
    data: {}
  },
  data() {
    return {
      visible: false,
      loading:false
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('close')
    },
    confirm() {
      this.loading = true
      console.log(this.controller, this.method, this.data)
      sendRequest(this.controller, this.method, this.data)
      .then(r=>{
        this.visible = false
        this.loading = false
        this.$emit("deleted")
      })
    }
  },
  watch: {
    isWindowVisible: function (val) {
      this.visible = val
    }
  }
}
</script>

<style scoped>

</style>
