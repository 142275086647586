<template>
  <CNav class="nav">
    <CNavItem>
      <!--      <CFormSelect v-if="isSmcTypeVisible" @change="selectChange">-->
      <!--        <option value="activeSmc">Активные подписки</option>-->
      <!--        <option value="inactiveSmc">Неактивные подписки</option>-->
      <!--        <option value="onetime">Разовые запросы</option>-->
      <!--      </CFormSelect>-->
    </CNavItem>
    <CNavItem v-for="(action) in actions">
      <CNavLink
        @click="actionClick(action)"
        :disabled="isDisabled(action.requireItems)"
        v-if="isVisible(action)"
      >
        <CSpinner v-if="action.isLoading" size="sm" class="me-2"/>
        <CIcon v-else :icon="action.icon" class="me-2"/>
        {{ action.label }}
      </CNavLink>
    </CNavItem>
    <CNavItem v-if="isSearchVisible" class="nav-search">
      <SearchInput/>
    </CNavItem>
  </CNav>
  <DeleteModal
    :is-window-visible="deleteWindowVisible"
    @close="deleteWindowVisible = false"
    @deleted="confirmDelete"
    :controller="controller"
    :method="method"
    :data="data"
  />
  <FormModal
    :is-visible="formModalVisible"
    @close="formModalVisible = false"
    @confirm="action => this.$emit('action', action)"
    :fields="modalFields"
    :data="modalData"
    :action-name="modalActionName"
    :operation-fields="modalOperationFields"
    :controller="controller"
    :method="method"
    :hidden-data="modalHiddenData"
  />
  <FormModalV2
    :is-visible="formModalVisibleV2"
    @close="formModalVisibleV2 = false"
    @confirm="action => this.$emit('action', action)"
    :formModel="getFormFields()"
  />
</template>

<script>
import DeleteModal from "../../widgets/DeleteModal";
import FormModal from "../../configurator/users/FormModal";
import SearchInput from "../../widgets/SearchInput";
import FormModalV2 from "../../widgets/FormModalV2";
import getFormFields from '../../../domain/use_case/getFormFields';
import getActionsUseCase from "../../../domain/use_case/getActionsUseCase";
import isActionVisibleUseCase from "../../../domain/use_case/action_bar/isActionVisibleUseCase";
import {downloadBlob, sendRequest} from "../../../services/api";


export default {
  name: "SmcActionBar",
  components: {FormModalV2, SearchInput, FormModal, DeleteModal},
  emits: ['action'],
  props: {
    isSearchVisible: {type: Boolean, default: true},
    responseData: {},
    selectedItems: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      availableActions: [],
      deleteWindowVisible: false,
      formModalVisible: false,
      formModalVisibleV2: false,
      sendSMSModalVisible: false,
      searchText: '',
      actions: getActionsUseCase(),
      controller: "",
      method: "",
      data: {},
      modalFields: [],
      modalData: {},
      modalActionName: "",
      modalOperationFields: [],
      modalHiddenData: {}
    }
  },
  methods: {
    callActionByLabel(label) {
      let action = this.actions.filter(item => { return item.label === label })[0]
      this.actionClick(action)
    },
    getRequestControllerAndMethod(name) {
      let action = this.availableActions.filter(item => {
        return item.name === name
      })
      if (action[0]) {
        this.controller = action[0].controller
        this.method = action[0].method
      }
    },
    //TODO: предполагаем что все запросы работают одинаково =)
    getDeleteData() {
      return {
        ID: this.selectedItems.map(item => {
          return item.ID
        })
      }
    },
    getOperationFields(name) {
      let action = this.availableActions.filter(item => {
        return item.name === name
      })
      if (action[0]) {
        return action[0].data
      }
      return []
    },
    isVisible(action) {
      return isActionVisibleUseCase(action, this.availableActions)
    },
    actionClick(action) {
      let name = action.name
      this.getRequestControllerAndMethod(action.label)
      this.getDeaultHeadersFileds() //todo refactor

      this.modalOperationFields = this.getOperationFields(action.label)

      this.modalHiddenData = {}
      Object.keys(this.modalFields).forEach(key => {
        if (this.modalFields[key].hidden) {
          this.modalHiddenData[key] = this.modalFields[key].value
        }
      })
      if (name === 'delete') {
        this.data = this.getDeleteData()
        this.deleteWindowVisible = true
      } else {
        this.modalActionName = name
        if (
          name === 'add'
          || name === "requestClose"
          || name === "requestDecline"
        ) {
          this.modalData = {}
          this.showModal(action)
        } else if (name === 'upload') {
          this.modalData = {}
          // this.modalHiddenData = {
          //   PartnerContractID: this.$route.params.id
          // }
          this.modalOperationFields = this.modalOperationFields.filter(item => item !== 'PartnerContractID')
          this.showModal(action)
        } else if (name === 'edit') {
          this.modalData = this.selectedItems[0]
          this.showModal(action)
          this.modalOperationFields = this.modalOperationFields.filter(item => item !== 'ID')
          // this.modalHiddenData = {
          //   ID: this.selectedItems[0].ID
          // }
        } else if (name === 'sendSMS') {
          this.modalFields = {
            "MSISDN": {
              type: "string",
              label: "Номер абонента"
            },
            "CenterID": {
              type: "int",
              label: "Центр",
            },
            "OperatorNumber": {
              type: "int",
              label: "КН",
            },
            "Message": {
              type: "string",
              label: "Сообщение",
            },
            "TaskKind": {
              type: "int",
              label: "Тип",
            }
          }
          this.showModal(action)
        } else if (
          name === 'exportB2C' ||
          name === 'exportExportbase' ||
          name === 'exportTemplates'
        ) {
          action.isLoading = true

          downloadBlob(action.controller, action.method, this.modalOperationFields)
            .then(r => {
              const fileURL = window.URL.createObjectURL(new Blob([r.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'report.xlsx');
              document.body.appendChild(fileLink);

              fileLink.click();
              action.isLoading = false
            })
            .catch(e => {
              action.isLoading = false
            })
        }  else if (name === 'requestApprove') {
          action.isLoading = true
          let ids = this.selectedItems.map(item => { return {ID: item.ID} })
          sendRequest(action.controller, action.method, ids)
            .then(r => {
              action.isLoading = false
              this.$emit('action', name)
            })
        } else {
          this.$emit('action', name)
        }
      }
    },
    confirmDelete() {
      this.deleteWindowVisible = false
      this.$emit('action', 'delete')
    },
    isDisabled(requireItems) {
      if (requireItems === 0) {
        return false
      }
      if (this.selectedItems.length > 0) {
        if (requireItems === 1 && this.selectedItems.length === 1) {
          return false
        }
        if (requireItems === Number.MAX_SAFE_INTEGER) {
          return false
        }
        return true
      } else {
        return true
      }
    },
    getDeaultHeadersFileds() {
      this.modalFields = this.responseData.data[0].result.fields
    },
    getFormFields() {
      return getFormFields(this.responseData, this.modalActionName, this.selectedItems[0])
    },
    showModal() {
      if ("formFields" in this.responseData.data[0].result) {
        this.formModalVisibleV2 = true
      } else {
        this.formModalVisible = true
      }
    }
  },
  watch: {
    responseData: function (val) {
      this.availableActions = val.data[0].result.operations.filter(item => {
        return item.type === 'action-bar' || item.type === 'inline_form'
      })
      this.modalFields = val.data[0].result.fields
    }
  }
}
</script>

<style scoped>
.nav-search {
  margin-left: auto;
}

.nav-search div {
  top: 0.3em;
  right: 0.5em;
}
</style>
