import getActionsUseCase from "./getActionsUseCase";

export default function getFormFields(responseData, actionName, selectedData)
{
  let actions = getActionsUseCase().filter(item => { return item.name === actionName })
  if (responseData && responseData.data && responseData.data.length>0 && actions.length>0) {
    let action = actions[0]
    let result = responseData.data[0].result
    let operations = result.operations.filter(item => { return item.name === action.label })
    // console.log(result.operations, action )

    if (operations.length > 0) {

      let data = []

      operations[0].data.forEach( item => {
        try {
          let dataItem = {...result.formFields[item]}
          switch (actionName) {
            case 'edit':
            case 'requestClose':
            case 'requestDecline':
            case 'upload':
              dataItem.value = selectedData[item]
              break
            default: {
            }
              break
          }
          data.push(dataItem)
          // console.log(item, data[item])
        } catch (e) {
          console.log(e)
        }
      } )
      return {
        formTitle: operations[0].formTitle,
        controller: operations[0].controller,
        actionName: actionName,
        method: operations[0].method,
        data: data
      }
    }
    return {}
  } else {
    return {}
  }
}
